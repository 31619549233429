import React from 'react';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const ToastInfo = ({ message, onClose }) => {
    return (
        <div
            id="toast-success"
            className="flex items-center p-4 text-gray-500 rounded-lg dark:text-gray-400 bg-gradient-to-r from-[#14263D] via-[#1a1f23] to-[#1a1f23]"
            role="alert"
        >
            <div className="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-full text-[#1a1f23] bg-[#3177FB]">
                <QuestionMarkIcon className='!text-base'/>    
            </div>
            <div className="ml-3 text-sm font-normal">{message || 'Item moved successfully.'}</div>
            <button
                type="button"
                className="ml-auto -mx-1.5 -my-1.5 text-gray-400 hover:text-gray-900 rounded-lg p-1.5 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white"
                onClick={onClose}
                aria-label="Close"
            >
                <span className="sr-only">Close</span>
                <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                >
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                </svg>
            </button>
        </div>
    );
};

export default ToastInfo;
