import React, { useEffect, useState } from "react";
import axios from "axios";
import { fetchTwitterToken, getAccessTokenTwitter } from "../utils/apis";
import { toast } from "react-toastify";
import TwitterLogo from './image_files/TwitterLogo.png';
import { Avatar, Box, Button } from "@mui/material";
import { Twitter } from "@mui/icons-material";
import ToastError from "../components/toasts/ToastError";
import ToastSuccess from "../components/toasts/ToastSuccess";
import { toastConfig } from "../utils/constants";

function TwitterLoginButton(props) {
  const [oauthToken, setOauthToken] = useState(null);
  const [oauthVerifier, setOauthVerifier] = useState(null);

  useEffect(() => {
    async function fetchTwitterToken() {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      if (params.has('oauth_token')) {
        setOauthToken(params.get('oauth_token'));
      }
      if (params.has('oauth_verifier')) {
        setOauthVerifier(params.get('oauth_verifier'));
      }
    }
    fetchTwitterToken();
  }, []);

  useEffect(() => {
    async function fetchAccessToken() {
      if (oauthToken && oauthVerifier) {
        try {
          const response = await getAccessTokenTwitter(oauthToken, oauthVerifier);
          if (response.status === 200) {
            // console.log(response.data);
            toast(<ToastError message={'You are now logged in'}/>, toastConfig); // Display success toast
            localStorage.setItem('usertoken', response.data.Token)
            localStorage.setItem('refreshToken', response.data.refreshToken)
            window.location.reload()
          } else {
            toast(<ToastError message={'Error while signing you up'}/>, toastConfig); // Display error toast
          }
        } catch (error) {
          console.error('Error fetching access token:', error);
          toast(<ToastError message={'Error while signing you up'}/>, toastConfig); // Display error toast
        }
      }
    }
    fetchAccessToken();
  }, [oauthToken, oauthVerifier]);

  const hanldeTwitterLogin = async () => {
    try {
      await fetchTwitterToken().then((response) => {
        console.log(response.data);
        const { oauthToken, oauthTokenSecret } = response.data;
        window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauthToken}&oauth_token_secret=${oauthTokenSecret}&oauth_callback_confirmed=true`;
      });
    }
    catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <>
      {/* <Button
            onClick={hanldeTwitterLogin}
            variant="text"
            size="small"
            startIcon={<Avatar src={'https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/twitter_circle-512.png'}
            sx={{ width: 40, height: 40 }}
            />}
        >
        </Button> */}

      <Box
        onClick={hanldeTwitterLogin}
        className="flex justify-center items-center"
      >
        {/* <Avatar src={'https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/twitter_circle-512.png'}
          sx={{ width: 40, height: 40, cursor: "pointer" }}
        /> */}

        {/* <button className='bg-transparent border-purple-500 border-solid border rounded-full p-3 hover:bg-[#1DA1F2] transition-all text-purple-500 hover:text-white hover:border-[#1DA1F2]'>
          <i class="fab fa-twitter"></i>
        </button> */}

        <button className='bg-transparent border-slate-700 border-solid border rounded-full p-3 hover:bg-[#1DA1F2] transition-all text-slate-500 hover:text-white hover:border-[#1DA1F2]'>
          <i class="fab fa-twitter"></i>
        </button>
      </Box>
    </>
  );
}

export default TwitterLoginButton;

