import React, { useEffect, useState } from 'react'
import LeaderCard from './LeaderCard';
import { useOutletContext } from 'react-router-dom';
import { fetchLeaderBoardData } from '../../utils/apis';
import Loader from '../../assets/loader';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import Dropdown from '../ui/Dropdown';
import { MenuItem } from '@mui/material';
import { ShareOutlined } from '@mui/icons-material';
import ShareOnSocial from 'react-share-on-social';
import { marmaOnlyLogo } from '../../assets/image_files';
const LeaderBoard = () => {
    const { filterParams, setRewardPoints: userRewardPoints } = useOutletContext()
    const [isLoading, setIsLoading] = useState(false)
    const [leaderboardData, setLeaderBoardData] = useState([])
    const [failureText, setFailureText] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [currentuserData, setCurrrentUserData] = useState()
    const [userRank, setuserRank] = useState()

    const indexOfLastItem = currentPage * itemsPerPage + 3
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentItems = leaderboardData.slice(indexOfFirstItem, indexOfLastItem)

    const totalPages = Math.ceil(leaderboardData.length / itemsPerPage)

    function changePage(number) {
        setCurrentPage(currentPage + number)
    }

    function handlePerPageChange(event) {
        setItemsPerPage(parseInt(event.target.value))
        setCurrentPage(1) // Reset to the first page
    }

    useEffect(() => {
        const fetchTable = async () => {
            setIsLoading(true)
            const response = await fetchLeaderBoardData(filterParams)

            if (response.status === 200) {
                let data = response.data.sort((a, b) => b.totalRewardPoints - a.totalRewardPoints)
                data = data.map((item, index) => ({ ...item, rank: index + 1 }))

                setLeaderBoardData(data)
                const user = data.filter((item) => item.isCurrentUser === true)
                setCurrrentUserData(user[0])
                setuserRank(user.rank)
                userRewardPoints(user.totalRewardPoints)
            } else setFailureText(true)

            setIsLoading(false)
        }
        fetchTable()
    }, [leaderboardData !== null, filterParams])

    if (isLoading) return (
        <div className='p-2'>
            <div className='text-gray-100 mb-4'>
                <h2 className='text-2xl'>Leaderboard</h2>
            </div>
            <Loader />
        </div>
    )

    if (failureText) return (
        <div className='p-2'>
            <div className='text-gray-100 mb-4'>
                <h2 className='text-2xl'>Leaderboard</h2>
                <p className='text-gray-400 text-md mt-2'>Something went wrong. Please try again...</p>
            </div>
        </div>
    )


    return (
        <div className='p-2'>
            <div className='text-gray-100 mb-4'>
                <h2 className='text-2xl'>Leaderboard</h2>
            </div>

            <div className='mt-6 grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3'>
                {
                    leaderboardData?.filter((item) => item.rank < 4).map((leader) => {
                        return (
                            <a href={`${window.location.origin}/publicdashboard?id=${leader.uniqueId}`} target='blank'>
                                <LeaderCard key={leader._id} rank={leader?.rank} name={leader.fullName} points={leader.totalRewardPoints} sqlSolved={leader.totalSQLQuestionsSolved} pythonSolved={leader.totalPythonQuestionsSolved} excelSolved={leader.totalExcelQuestionsSolved} totalQuestionSolved={leader.totalQuestionsSolved} />
                            </a>
                        )
                    })
                }
            </div>
            {
                leaderboardData.length > 4 &&
                <>
                    <div id="leaders-table" className='overflow-x-auto'>
                        <div className='bg-[#303436] mt-3 mb-1 flex justify-between px-6 py-2 text-sm text-gray-500 rounded-lg text-center'>
                            <span className='w-8 flex justify-center items-center'>Rank</span>
                            <span className='w-full flex justify-center items-center'>Player</span>
                            <span className='w-full flex justify-center items-center'>Points</span>
                            <span className='w-full flex justify-center items-center'>Total Questions Solved</span>
                            <span className='w-full flex justify-center items-center'>SQL</span>
                            <span className='w-full flex justify-center items-center'>Python</span>
                            <span className='w-full flex justify-center items-center'>Excel</span>
                        </div>
                    </div>

                    {
                        currentItems?.map((leader, i) =>
                            <a key={leader?._id} className={`${i % 2 !== 0 ? "bg-[#3c4144]" : "bg-[#303436]"} hover:bg-[#484e51] transition-all mb-1 flex justify-between px-6 py-2 text-sm text-gray-400 rounded-lg text-center`}
                                href={`${window.location.origin}/publicdashboard?id=${leader.uniqueId}`}
                                target='blank'
                            >
                                <span className='w-8 flex justify-center items-center'>
                                    <span className="inline-flex items-center justify-center w-6 h-6 me-2 text-sm font-semibold text-gray-300 bg-[#60686c] bg-opacity-40 rounded-full">
                                        {leader?.rank}
                                    </span>
                                </span>
                                <span className='w-full text-gray-200 flex justify-center items-center'>{leader?.fullName}</span>
                                <span className='w-full flex justify-center items-center'>{leader?.totalRewardPoints}</span>
                                <span className='w-full flex justify-center items-center'>{leader?.totalQuestionsSolved}</span>
                                <span className='w-full flex justify-center items-center'>{leader?.totalSQLQuestionsSolved}</span>
                                <span className='w-full flex justify-center items-center'>{leader?.totalPythonQuestionsSolved}</span>
                                <span className='w-full flex justify-center items-center'>{leader?.totalExcelQuestionsSolved}</span>
                            </a>
                        )
                    }
                    <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700" />

                    <a className="flex h-full w-full hover:bg-amber-500 hover:bg-opacity-30 transition-all mb-1 flex justify-between px-6 py-2 text-sm text-gray-400 rounded-lg text-center bg-[#F8D21E] bg-opacity-10 font-semibold relative"
                        href={`${window.location.origin}/publicdashboard?id=${currentuserData?.uniqueId}`}
                        target='blank'
                    >
                        <span className='w-8 flex justify-center items-center'>
                            <span className="inline-flex items-center justify-center w-6 h-6 me-2 text-sm font-semibold text-gray-300 bg-[#60686c] bg-opacity-40 rounded-full">
                                {currentuserData?.rank}
                            </span>
                        </span>
                        <span className='w-full text-gray-200 flex justify-center items-center'>{currentuserData?.fullName}</span>
                        <span className='w-full flex justify-center items-center'>{currentuserData?.totalRewardPoints}</span>
                        <span className='w-full flex justify-center items-center'>{currentuserData?.totalQuestionsSolved}</span>
                        <span className='w-full flex justify-center items-center'>{currentuserData?.totalSQLQuestionsSolved}</span>
                        <span className='w-full flex justify-center items-center'>{currentuserData?.totalPythonQuestionsSolved}</span>
                        <span className='w-full flex justify-center items-center'>{currentuserData?.totalExcelQuestionsSolved}</span>

                        <ShareOnSocial
                            textToShare="find a detailed overview of my recent progress and achievements. Whether you're a prospective employer, a fellow professional, or just curious, I invite you to delve into the analytics of my journey."
                            link={`${window.location.origin}/publicdashboard?id=${currentuserData?.uniqueId}`}
                            linkTitle="Welcome to Public Profile"
                            linkMetaDesc=""
                            linkFavicon={marmaOnlyLogo}
                            noReferer>
                            <ShareOutlined className='absolute right-10 hover:bg-amber-400 hover:bg-opacity-50 transition-all text-gray-200 rounded-full p-1' />
                        </ShareOnSocial>
                    </a>
                </>
            }
            <div id='pagination' className='flex justify-end gap-2 mt-2'>
                <button
                    disabled={currentPage === 1}
                    className={`p-0 ${currentPage === 1 ? "text-gray-600" : "text-gray-400"}`}
                    onClick={() => changePage(-1)}>
                    <ArrowBackIosIcon />
                </button>
                <button
                    disabled={currentPage === totalPages}
                    className={`p-0 ${currentPage === totalPages ? "text-gray-600" : "text-gray-400"}`}
                    onClick={() => changePage(1)}>
                    <ArrowForwardIosIcon />
                </button>
                <Dropdown
                    title={itemsPerPage}
                    children={
                        <>
                            <MenuItem
                                onClick={handlePerPageChange}
                                className='!text-gray-200 !font-light !text-xs'
                                value={10} key={10}>10
                            </MenuItem>

                            <MenuItem
                                onClick={handlePerPageChange}
                                className='!text-gray-200 !font-light !text-xs'
                                value={25} key={25}>25
                            </MenuItem>

                            <MenuItem
                                onClick={handlePerPageChange}
                                className='!text-gray-200 !font-light !text-xs'
                                value={50} key={50}>50
                            </MenuItem>
                        </>
                    }
                />
            </div>
        </div>
    )
}

export default LeaderBoard