import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { sendGoogleTokenToBackend } from "../utils/apis";
import { Box } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../utils/constants";
import ToastError from '../components/toasts/ToastError'
import ToastSuccess from '../components/toasts/ToastSuccess'

const GoogleLoginComponent = () => {
  const triggerGoogleLoginPopup = () => {
    try {
      const googleAuth = window.google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, // Replace with your Google Client ID
        scope: "profile email", // Define the required scopes
        prompt: "consent", // Force the consent screen to appear
        callback: async (response) => {
          console.log("Google response:", response);

          if (!response || !response.access_token) {
            console.error("No access token received.");
            toast(<ToastError message={"Login failed. Please try again."}/>, toastConfig);
            return;
          }

          // Send the ID token to your backend
          try {
            const backendResponse = await sendGoogleTokenToBackend(response.access_token);
            console.log("Backend response:", backendResponse);

            if (backendResponse.status === 200) {
              toast(<ToastSuccess message={"Login successful"}/>, toastConfig);
              localStorage.setItem("usertoken", backendResponse.data.Token);
              localStorage.setItem("refreshToken", backendResponse.data.refreshToken);
              window.location.reload();
            } else {
              toast(<ToastError message={"Error while logging you in"}/>, toastConfig);
            }
          } catch (error) {
            console.error("Error sending token to backend:", error);
            toast(<ToastError message={"Something went wrong!"}/>, toastConfig);
          }
        },
      });

      // Open the Google login popup
      googleAuth.requestAccessToken();
    } catch (error) {
      console.error("Error triggering Google Login popup:", error);
      toast(<ToastError message={"Something went wrong. Please try again later."}/>, toastConfig);
    }
  };

  useEffect(() => {
    if (!window.google || !window.google.accounts) {
      console.error("Google API is not loaded.");
    }
  }, []);

  return (
    <Box className="flex justify-center items-center">
      <button
        className="bg-transparent border-slate-700 border-solid border rounded-full p-3 hover:bg-[#dd4b39] transition-all text-slate-500 hover:text-white hover:border-[#dd4b39]"
        onClick={triggerGoogleLoginPopup}
      >
        <i className="fab fa-google"></i>
      </button>
    </Box>
  );
};

export default GoogleLoginComponent;
