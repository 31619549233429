import React, { useState } from "react"
import { toast } from "react-toastify"
import { isEmailValid } from "../utils/common-function"

import { siginInApi, sendResetLink } from "../utils/apis"
import GoogleLoginComponent from "../assets/google-auth.js"

import { CheckIcon, XIcon, RefreshIcon } from "@heroicons/react/outline"
import LinkedInLoginButton from "../assets/linkedin-auth.js"
import TwitterLoginButton from "../assets/twitter-auth.js"
import { Box, CircularProgress, Divider, Link as MaterialLink, Typography } from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Link } from "react-router-dom"
import { toastConfig } from "../utils/constants.js"
import BackgroundDiv from "./background/BackgroundDiv.jsx"
import ToastError from "./toasts/ToastError.js"
import ToastSuccess from "./toasts/ToastSuccess.js"

const SignInDup = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [resetStatus, setResetStatus] = useState("idle")
  const [showPassword, setShowPassword] = useState(false)
  const [showResetPopup, setShowResetPopup] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  //const dispatch = useDispatch();
  //const navigate = useNavigate();

  const handleForgotPassword = () => {
    if (email) {
      setShowResetPopup(!showResetPopup)
    } else {
      toast.dismiss()
      toast(<ToastError message={"Enter Your email to send Verification Link"}/>, toastConfig)
    }
  }

  const handleResetClick = async () => {
    setResetStatus("loading")
    const response = await sendResetLink({ email })
    let x = response.status === 200 ? "success" : "error"
    setResetStatus(x)
  }

  const renderStatusIcon = (status) => {
    switch (status) {
      case "loading":
        return <RefreshIcon className="animate-spin h-5 w-5 text-white" />
      case "success":
        return <CheckIcon className="h-5 w-5 text-sky-800" />
      case "error":
        return <XIcon className="h-5 w-5 text-red-800" />
      default:
        return null
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // Normally, you'd make an API call here to authenticate the user.
    // For simplicity, let's mock a successful response.
    setIsSubmitting(true)

    if (!email || !password) {
      toast.dismiss()
      toast(<ToastError message={"Please fill all the mandatory fields"}/>, toastConfig)
      setIsSubmitting(false)

      return
    }

    if (!isEmailValid(email)) {
      toast.dismiss()
      toast(<ToastError message={"Invalid email"}/>, toastConfig)
      setIsSubmitting(false)
      return
    }
    try {
      let response = await siginInApi({ email, password })
      if (response.status === 200) {
        toast.dismiss()
        toast(<ToastSuccess message={"Signin successful"}/>, toastConfig) // Display success toast
        localStorage.setItem("usertoken", response.data.token)
        localStorage.setItem("refreshToken", response.data.refreshToken)
        window.location.reload()
        setIsSubmitting(false)
      } else {
        toast.dismiss()
        toast(<ToastError message={response.message || "Error while signing you in"}/>, toastConfig) // Display error toast
        setIsSubmitting(false)
      }
    } catch (error) {
      // console.error(error)
      console.log("I am in error block")
      console.log(error)
      toast.dismiss()
      toast(<ToastError message={error}/>, toastConfig) // Display error toast
      setIsSubmitting(false)
    }
  }

  return (
    <BackgroundDiv>
      <div className="text-white flex justify-center items-center">
        <div>
          <Typography
            variant="h4"
            component="h4"
            my={2}
            align="center"
            sx={{ fontWeight: "bold" }}
            fontFamily="inherit">
            Sign In
          </Typography>

          <form
            onSubmit={handleSubmit}
            className="myCard p-6">
            <input
              className="input mb-3"
              required
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email Address"
              autocomplete="off"
            />

            <div className="relative">
              <input
                className="bg-[#222630] pl-4 pr-10 py-2 outline-none w-full text-white rounded-lg border-2 transition-colors duration-100 border-solid focus:border-[#596A95] border-[#2B3040] text-base"
                required
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                autoComplete="off"
              />
              {/* Show/hide password toggle icon */}
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 flex items-center text-gray-400">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </button>
            </div>

            <Box my={1}>
              <MaterialLink
                onClick={handleForgotPassword}
                className="!text-sm"
                sx={{ cursor: "pointer", color: "#5F6368", textDecoration: "none" }}>
                Forgot Password?
              </MaterialLink>
            </Box>

            <Box className="flex justify-center items-center flex-col">
              {isSubmitting ? (
                <>
                  <svg
                    width={0}
                    height={0}>
                    <defs>
                      <linearGradient
                        id="my_gradient"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%">
                        <stop
                          offset="0%"
                          stopColor="#e01cd5"
                        />
                        <stop
                          offset="100%"
                          stopColor="#1CB5E0"
                        />
                      </linearGradient>
                    </defs>
                  </svg>
                  <CircularProgress sx={{ "svg circle": { stroke: "url(#my_gradient)" } }} />
                </>
              ) : (
                <button
                  className="cta-btn w-full text-base rounded-full px-4 py-2 flex justify-center items-center"
                  onClick={handleSubmit}
                  style={{
                    fontFamily: "inherit",
                    textAlign: "center",
                  }}>
                  Sign In
                </button>
              )}
            </Box>

            <Divider
              sx={{
                "&::before, &::after": {
                  borderColor: "#5F6368",
                },
                marginY: "1rem",
              }}
              className="!text-base"
              >
              OR
            </Divider>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: ["repeat(3, 1fr)", "repeat(3, 1fr)", "repeat(3, 1fr)"], // Responsive grid layout
                gap: "1rem", // Gap between grid items
              }}
              my={2}>
              <GoogleLoginComponent />
              <LinkedInLoginButton />
              <TwitterLoginButton />
            </Box>

            <Typography
              align="center"
              my={1}
              className="!text-base"
              fontFamily="inherit">
              Don't have an account?
              <Link
                to="/signup"
                className="font-bold text-purple-500">
                {" "}
                Sign Up
              </Link>
            </Typography>
          </form>

          {showResetPopup && (
            <div className="right-10 p-4 bg-transparent rounded shadow-lg mb-30">
              <button
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                onClick={handleResetClick}>
                Send Reset Link
                {renderStatusIcon(resetStatus)}
              </button>
            </div>
          )}
        </div>
      </div>
    </BackgroundDiv>
  )
}

export default SignInDup
