import React, { useState } from "react"
import { toast } from "react-toastify"
import { updatePassword } from "../utils/apis"
import GoogleLoginComponent from "../assets/google-auth.js"
import { TextField, Button, Paper, Box, Typography, Container } from "@mui/material"
import { Link, Navigate } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import BackgroundDiv from "./background/BackgroundDiv.jsx"
import { toastConfig } from "../utils/constants.js"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { validatePassword } from "../utils/common-function.js"
import ToastError from "./toasts/ToastError.js"
import ToastSuccess from "./toasts/ToastSuccess.js"

const ForgotPassword = () => {
  const navigate = useNavigate()

  const [password, setPassword] = useState("")
  const [confPassword, setconfPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [showConfPassword, setShowConfPassword] = useState(false)
  const [passwordError, setpasswordError] = useState({ status: false, msg: "" })

  const checkValidPassword = (pass, type) => {
    const response = validatePassword(pass)
    setpasswordError(response)

    type === "pwd" ? setPassword(pass) : setconfPassword(pass)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // Normally, you'd make an API call here to authenticate the user.
    // For simplicity, let's mock a successful response.
    const params = new URLSearchParams(window.location.search)
    const token = params.get("token")

    if (password !== confPassword) {
      toast(<ToastError message={"Passwords donot match"}/>, toastConfig)
    }
    try {
      let response = await updatePassword({ password, token })
      if (response.status == 200) {
        toast(<ToastSuccess message={"Password Updated!"}/> ,toastConfig) // Display success toast
        navigate("/signin")
      } else {
        toast(<ToastError message={response.message || "Unable to update password"}/>, toastConfig) // Display error toast
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <BackgroundDiv>
        <div className="text-white flex justify-center items-center">
          <div className="w-full">
            <Typography
              variant="h4"
              component="h4"
              my={2}
              align="center"
              sx={{ fontWeight: "bold" }}
              fontFamily="inherit">
              Reset Password
            </Typography>

            <form
              onSubmit={handleSubmit}
              className="myCard p-6 max-w-md mx-auto">
              <div className="relative my-3">
                <input
                  className="input pr-10" // Added pr-10 for padding right
                  required
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => checkValidPassword(e.target.value, "pwd")}
                  placeholder="Password"
                  autoComplete="off"
                />
                {/* Show/hide password toggle icon */}
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-1 flex items-center text-gray-400">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </button>
              </div>
              <div className="relative my-3">
                <input
                  className="input pr-10" // Added pr-10 for padding right
                  required
                  type={showConfPassword ? "text" : "password"}
                  value={confPassword}
                  onChange={(e) => checkValidPassword(e.target.value, "confpwd")}
                  placeholder="Confirm Password"
                  autoComplete="off"
                />
                {/* Show/hide password toggle icon */}
                <button
                  type="button"
                  onClick={() => setShowConfPassword(!showConfPassword)}
                  className="absolute inset-y-0 right-1 flex items-center text-gray-400">
                  {showConfPassword ? <VisibilityOff /> : <Visibility />}
                </button>
              </div>
              <button
                className="cta-btn w-full rounded-full px-4 py-2 flex justify-center items-center"
                onClick={handleSubmit}
                style={{
                  fontFamily: "inherit",
                  textAlign: "center",
                }}
                type="submit">
                Update Password
              </button>
              <Typography
                align="center"
                my={1}
                fontFamily="inherit">
                Already have an account?
                <Link
                  to="/signin"
                  className="font-bold text-purple-500">
                  {" "}
                  Sign In
                </Link>
              </Typography>
              {passwordError.status && (
                <>
                  <Typography
                    my={1}
                    color="#BB2C35"
                    variant="subtitle2"
                    fontFamily="inherit"
                    gutterBottom>
                    {passwordError.msg}
                  </Typography>
                  <div className="my-2">
                    <ul style={{ listStyle: "disc", paddingLeft: "1.5rem", marginTop: 0, color: "#94a3b8" }}>
                      <li>
                        <Typography
                          variant="subtitle2"
                          fontFamily="inherit">
                          At least 8 characters long
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          variant="subtitle2"
                          fontFamily="inherit">
                          Contains both uppercase and lowercase letters (A-Z)
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          variant="subtitle2"
                          fontFamily="inherit">
                          Contains numbers (0-9)
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          variant="subtitle2"
                          fontFamily="inherit">
                          Contains special characters (like !, @, #, $, etc.)
                        </Typography>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </BackgroundDiv>
      {/*<Container
        component="main"
        maxWidth="xs">
        <Paper
          elevation={6}
          sx={{ marginTop: 8, padding: 4 }}>
          <Typography
            component="h1"
            variant="h5"
            align="center">
            Reset Password
          </Typography>
          <Box sx={{ mt: 1 }}>
            <form onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                fullWidth
                id="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <TextField
                margin="normal"
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                type="password"
                autoComplete="current-password"
                value={confPassword}
                onChange={(e) => setconfPassword(e.target.value)}
                required
              />
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="register-btn"
                type="submit">
                Update Password
              </Button>
            </form>
            <Link to="/siginin">
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="register-btn"
                type="button">
                Back to Sign In
              </Button>
            </Link>
          </Box>
        </Paper>
      </Container> */}
    </>
  )
}

export default ForgotPassword
