import React, { useState } from 'react'
import './Newsletter.css'
import { isEmailValid } from '../../utils/common-function'
import { toast } from 'react-toastify'
import { addSubscriber } from '../../utils/apis'
import { CircularProgress } from '@material-ui/core'
import { toastConfig } from '../../utils/constants'
import ToastError from '../toasts/ToastError'
import ToastSuccess from '../toasts/ToastSuccess'

const Newsletter = () => {
    const [email, setEmail] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)
    const handleSubscribe = async () => {
        setIsSubmitting(true)
        if (!email) {
            toast.dismiss()
            toast(<ToastError message={"Enter Your Email"}/>, toastConfig)
            setIsSubmitting(false)
            return
        }
        if (!isEmailValid(email)) {
            toast.dismiss()
            toast(<ToastError message={'Invalid Email, Retry Again!'}/>, toastConfig);
            setIsSubmitting(false)

            return
        }
        else {
            try {
                let response = await addSubscriber({ email })
                if (response.status === 200) {
                    toast.dismiss()
                    toast(<ToastSuccess message={'Thanks for Subscribing to Us'}/>, toastConfig);
                    setEmail('');
                } else {
                    toast.dismiss()
                    toast(<ToastError message={response.message || 'Error while signing you in'}/>, toastConfig);
                }
            } catch (error) {
                console.error(error);
            }
            finally {
                setIsSubmitting(false)

            }
        }
    }
    return (
        <>
            <div className="notification p-6">
                <div className="notiglow"></div>
                <div className="notiborderglow"></div>
                <div className="notititle">Get Our Latest Updates & Promotions</div>
                <div className="notibody">We recommended you to subscribe to our newsletter, drop your email below
                    <div className="md:flex items-center mt-2 sm:block">
                        <div className="relative flex-grow">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                                    <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                                    <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                                </svg>
                            </div>
                            <input type="text" className="bg-[#222630] px-10 py-2 outline-none w-full text-white rounded-lg border-2 transition-colors duration-100 border-solid focus:border-[#596A95] border-[#2B3040]" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>

                        {isSubmitting ?
                            <>
                                <svg
                                    width={0}
                                    height={0}
                                    className='md:mx-2'>
                                    <defs>
                                        <linearGradient
                                            id="my_gradient"
                                            x1="0%"
                                            y1="0%"
                                            x2="0%"
                                            y2="100%">
                                            <stop
                                                offset="0%"
                                                stopColor="#e01cd5"
                                            />
                                            <stop
                                                offset="100%"
                                                stopColor="#1CB5E0"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <CircularProgress sx={{ "svg circle": { stroke: "url(#my_gradient)" } }} />
                            </>
                            :

                            <button
                                type="button"
                                className="px-5 py-2 font-medium text-white cta-btn rounded-full mx-2 md:mx-3 mt-2 md:mt-0"
                                onClick={handleSubscribe}
                            >
                                Subscribe
                            </button>


                        }



                    </div>
                </div>
            </div>
        </>
    )
}

export default Newsletter