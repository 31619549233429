import React, { useEffect } from 'react';

const SWChartCustomTooltip = ({ active, payload, filteredData, setTooltipData }) => {
  useEffect(() => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      // Filter the data based on rewards
      const tempData = filteredData.filter((d) => d.rewards === data.rewards);
      setTooltipData(tempData);
    }
  }, [active]);

  if (active && payload && payload.length) {
    const data = payload[0].payload;
    // Filter the data based on rewards
    const tempData = filteredData.filter((d) => d.rewards === data.rewards);

    return (
      // <div
      //   style={{
      //     backgroundColor: "#343839",
      //     padding: "1rem",
      //     border: "0.08rem solid #fefefe1a",
      //     color: "white",
      //     borderRadius: "0.5rem",
      //     maxHeight: "200px",
      //     overflowY: "auto",
      //     pointerEvents: "none",
      //   }}
      // >
      //   {
      //     tempData.map((d, index) => (
      //       <div key={index} className="text-sm">
      //         <p>
      //           <span className="font-bold">Function:</span> {d.name}
      //         </p>
      //         <p>
      //           <span className="font-bold">Total Runs:</span> {d.totalRuns}
      //         </p>
      //         <p>
      //           <span className="font-bold">Total Rewards:</span> {d.rewards}
      //         </p>
      //         <p>
      //           <span className="font-bold">Total Correctly Submitted Solutions:</span> {d.totalCorrectlySubmittedSol}
      //         </p>
      //         <p className="mb-2">
      //           <span className="font-bold">Accuracy:</span> {d.accuracy.toFixed(2)}%
      //         </p>
      //       </div>
      //     ))
      //   }
      // </div>
      <></>
    );
  }

  return null;
};

export default SWChartCustomTooltip;
