import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import CommonHeader from "../assets/common-header"
import { fetchArenasList } from "./../redux/actions/arena"
import Loader from "../assets/loader"
import { fetchUserDetails } from "../redux/actions/user"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import "../assets/css_files/arenas.css"
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded"
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded"
import { NoDataFound } from "../assets/image_files"
import { Tooltip } from "@mui/material"
import { Button } from "@material-ui/core"
import { Paid, ReadMore } from "@mui/icons-material"
import ToastError from "./toasts/ToastError"
import { toastConfig } from "../utils/constants"


function Arenas() {
  const [showArena, setActiveArena] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { availableArenas, enrolledArenas, userDetails } = useSelector((state) => ({
    availableArenas: state.arena.availableArenas,
    enrolledArenas: state.arena.enrolledArenas,
    userDetails: state.user.userDetails,
  }))

  useEffect(() => {
    dispatch(fetchArenasList())
    dispatch(fetchUserDetails())
  }, [dispatch])

  if (userDetails && !userDetails.isVerified) {
    toast(<ToastError message={"Please verify your email to continue"}/>, toastConfig)
    navigate("/verify-email")
  }


  return (
    <div>
      <CommonHeader />
      <div className="arenas-homepage-container p-4">
        <h1 className="font-semibold text-2xl text-gray-200 mb-3">Arenas - Pathway to domain expertise</h1>
        <div className="flex gap-4 h-full">

          <div className="enrolled-arenas-container relative w-[25%] h-full overflow-hidden">
            <div className="sticky top-0 bg-white z-10">
              <h2 className="title-line enrl-avl-heading text-base p-2 text-gray-200">Enrolled Arenas</h2>
            </div>
            <div className="p-2 h-[calc(100vh-8rem)] overflow-auto">
              {
                enrolledArenas?.length === 0 && (
                  <div className="flex flex-col justify-center items-center h-full">
                    <img src={NoDataFound} alt="No Data Found" className="w-[150px] mb-3 opacity-35" />
                    <h2 className="text-base text-gray-400">No Enrollments</h2>
                  </div>
                )
              }
              {enrolledArenas?.map((arena) => (
                <div key={arena._id} className="bg-[#232627] mb-4 p-2 rounded-md">
                  <div className="flex justify-between items-center mb-2">
                    <Link to={`/arenas/${arena.name}`} className="text-blue-600">
                      <span className="text-gray-200 font-medium mb-1 hover:text-gray-400 transition-all">{arena.name} Arena</span>
                    </Link>
                    <div className="bg-[#343839] text-[#D0AA45] text-xs font-medium px-2.5 py-1 rounded cursor-default">
                      <Tooltip
                        placement="top"
                        arrow
                        title="Learners Enrolled">
                        <p className="">
                          <PeopleAltRoundedIcon sx={{ height: "18px", width: "18px" }} className="me-1" />
                          <span>{arena.enrolledUsers.length}</span>
                        </p>
                      </Tooltip>
                    </div>
                  </div>
                  {arena?.description && (
                    <p className="text-sm text-gray-400">{arena.description.length > 100 ? arena.description.substring(0, 100) + "..." : arena.description}</p>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="enrolled-arenas-container w-full overflow-auto relative">
            <div className="sticky top-0 bg-white z-10">
              <h2 className="title-line enrl-avl-heading text-base p-2 text-gray-200">Available Arenas</h2>
            </div>
            <div
              className="arenas-list-description h-[calc(100vh-8rem)] overflow-auto">
              {
                availableArenas?.length === 0 && (
                  <div className="flex flex-col justify-center items-center h-full">
                    <img src={NoDataFound} alt="No Data Found" className="w-[150px] mb-3 opacity-35" />
                    <h2 className="text-base text-gray-400">No Arenas Available</h2>
                  </div>
                )
              }
              <div className="arenas-avl-list grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 overflow-auto p-2">
                {availableArenas?.map((arena) => (
                  <>
                    <div
                      key={arena._id}
                      className={`arena-item rounded-md my-2 p-3 cursor-pointer hover:scale-[1.01] transition-all ${showArena && showArena.name === arena.name ? "!bg-gray-800" : ""}`}>
                      <div className="flex items-center w-full gap-2">
                        <span className="text-gray-200 font-medium mb-1">{arena.name} Arena</span>
                      </div>

                      <div className="arena-description mb-2">
                        <p className={`font-normal text-gray-400 text-left cursor-auto text-sm`}>
                          {arena.description && <p>{arena.description.length > 100 ? arena.description.substring(0, 100) + "..." : arena.description}</p>}
                        </p>
                      </div>

                      <div className="flex gap-3">
                        {/* <div className="bg-[#343839] text-[#D0AA45] text-xs font-medium px-2.5 py-1 rounded cursor-default">
                          <p className="">
                            <Paid sx={{ height: "18px", width: "18px" }} className="me-1" />
                            <span>{arena.isPremium.charAt(0).toUpperCase() + arena.isPremium.slice(1)}</span>
                          </p>
                        </div> */}

                        <div className="bg-[#343839] text-[#D0AA45] text-xs font-medium px-2.5 py-1 rounded cursor-default">
                          <Tooltip
                            placement="top"
                            arrow
                            title="Learners Enrolled">
                            <p className="">
                              <PeopleAltRoundedIcon sx={{ height: "18px", width: "18px" }} className="me-1" />
                              <span>{arena.enrolledUsers.length}</span>
                            </p>
                          </Tooltip>
                        </div>
                      </div>

                      <div className="flex gap-2 items-center justify-between w-full mt-3">
                        <Button variant="outlined" className="w-full !text-gray-400 !border-gray-400 !shadow-none" size="small"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Link to={`/arenas/${arena?.name}`}>Explore</Link>
                        </Button>
                      </div>
                    </div>
                  </>
                ))}
              </div>
              {/* <ArenaDescription currArena={showArena} /> */}
            </div>
            {/* <span className={`transparent_gradient`}></span> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Arenas
