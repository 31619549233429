import React, { useState, useEffect } from "react"
import { faCancel, faEdit, faUpload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@mui/material"
import { toast } from "react-toastify"
import { toastConfig } from "../utils/constants"
import Loader from "./loader"
import { updateAccountInfo, sendResetLink, addReferral } from "../utils/apis"
import { validateName } from "../utils/common-function"
import { CheckIcon, XIcon, RefreshIcon } from "@heroicons/react/outline"
import { PasswordOutlined } from "@mui/icons-material"
import { fetchUserDetails } from "../redux/actions/user"
import { useDispatch } from "react-redux"
import ToastError from "../components/toasts/ToastError"
import ToastSuccess from "../components/toasts/ToastSuccess"


const Account = ({ name, email }) => {
  const dispatch = useDispatch()

  const [user, setUser] = useState({
    firstName: name.split(" ")[0],
    lastName: name.split(" ")[1],
    email: email,
  })
  const [initialData, setInitialData] = useState({ ...user })
  const [isEditable, setIsEditable] = useState(false)
  const [loading, setLoading] = useState(false) // Initially true
  const [showResetBtn, setShowResetBtn] = useState(false)
  const [resetStatus, setResetStatus] = useState("idle") // Same as above
  const [referralCode, setReferralCode] = useState("")

  const handleResetClick = async () => {
    setResetStatus("loading")
    const response = await sendResetLink({ email })
    let x = response.status === 200 ? "success" : "error"
    setResetStatus(x)
  }

  const renderStatusIcon = (status) => {
    switch (status) {
      case "idle":
        return <PasswordOutlined />
      case "loading":
        return <RefreshIcon className="animate-spin h-5 w-5 text-gray-400" />
      case "success":
        return <CheckIcon className="h-5 w-5 text-green-400" />
      case "error":
        return <XIcon className="h-5 w-5 text-red-400" />
      default:
        return null
    }
  }

  const handleEditClick = () => {
    setIsEditable(!isEditable)
    !isEditable ? setShowResetBtn(true) : setShowResetBtn(false)
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    const response = validateName(value)
    if (response.status) {
      toast(<ToastError message={response.msg}/>, toastConfig)
    }
    setUser({ ...user, [name]: value })
  }

  const addReferralCode = async () => {
    if (referralCode.length === 0) {
      toast.error("Please enter a referral code", toastConfig)
    } else {
      try {
        const response = await addReferral(referralCode)
        if (response.status === 200) {
          toast.success("Referral code added successfully", toastConfig)
          setReferralCode("")
        }
      } catch (e) {
        toast.error("Unable to add you referral code", toastConfig)
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (JSON.stringify(initialData) === JSON.stringify(user)) {
      toast(<ToastError message={"Modify any field to update"}/>, toastConfig)
      setIsEditable(false)
      return
    }
    const userData = { firstName: user.firstName, lastName: user.lastName }
    const response = await updateAccountInfo(userData) // Update data via API
    if (response.status === 200) {
      toast(<ToastSuccess message={"Account details updated successfully"}/>, toastConfig)
      dispatch(fetchUserDetails())
    } else {
      toast(<ToastError message={"Failed to update, try again"}/>, toastConfig)
    }
    setIsEditable(false)
  }

  return (
    <>
      <div className="shiny-border mb-4">
        <div className="flex justify-between sticky  bg-[#1f2021] top-[3rem] items-baseline p-3 rounded-[18px] z-20">
          <h2 className="font-semibold text-[#e5e7eb] text-base">Account</h2>
          <div className="flex-row-container gap-2">
            <Button
              style={{ fontFamily: "inherit" }}
              variant="outlined"
              className="
            !font-medium hover:!text-yellow-600 hover:!bg-transparent hover:border-yellow-600 !rounded-lg !text-sm !px-2 !py-1.5 !capitalize  !text-gray-400 !border-gray-600"
              endIcon={
                isEditable ? (
                  <FontAwesomeIcon
                    className="!text-sm"
                    icon={faCancel}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="!text-sm"
                    icon={faEdit}
                  />
                )
              }
              onClick={handleEditClick}>
              {isEditable ? "Cancel" : "Edit"}
            </Button>

            {isEditable && (
              <Button
                variant="outlined"
                style={{ fontFamily: "inherit" }}
                className="!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:border-yellow-600 !rounded-lg !text-sm !px-2 !py-1.5 !capitalize  !text-gray-400 !border-gray-600"
                onClick={handleSubmit}>
                Update
                <FontAwesomeIcon
                  icon={faUpload}
                  style={{ height: "14px" }}
                />
              </Button>
            )}
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="shiny-border">
          <div className="myCard p-6 !bg-[#1f2021]">
            <div className="flex-row-bw-container gap-4">
              <fieldset className="profile-fieldset flex-1">
                <legend className="profile-legend">First Name</legend>
                <input
                  type="text"
                  className="profile-input input mb-3"
                  placeholder={isEditable ? "First Name" : ""}
                  name="firstName"
                  value={user.firstName || ""}
                  disabled={!isEditable}
                  onChange={handleChange}
                />
              </fieldset>
              <fieldset className="profile-fieldset flex-1">
                <legend className="profile-legend">Last Name</legend>
                <input
                  type="text"
                  className="profile-input input mb-3"
                  placeholder="Last Name"
                  name="lastName"
                  value={user.lastName || ""}
                  disabled={!isEditable}
                  onChange={handleChange}
                />
              </fieldset>
            </div>
            <fieldset>
              <legend className="profile-legend">Email ID</legend>
              <input
                type="email"
                className="profile-input input mb-3"
                placeholder="Email"
                name="email"
                value={user.email || ""}
                disabled={true}
              />
            </fieldset>
          </div>
        </div>
      )}
      <div className="shiny-border my-3">
        <div className="flex flex-col justify-start items-start gap-4 myCard p-6 !bg-[#1f2021]">
          <div className="">
            <h2 className="font-medium mb-3 text-base mt-3 text-[#e5e7eb]">Reset Password </h2>
            <Button
              //disabled={!isEditable}
              variant="outlined"
              className="!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:!border-yellow-600 !rounded-lg !text-sm !px-2 !py-1.5 !capitalize  !text-gray-400 !border-gray-600"
              onClick={handleResetClick}>
              {resetStatus === "success" ? "Link sent" : resetStatus === "idle" ? "Reset password" : resetStatus === "error" ? "Try again" : "Check email, for reset link"}
              {renderStatusIcon(resetStatus)}
            </Button>
          </div>
          <h2 className="font-medium  text-base mt-3 text-[#e5e7eb]">Add Referral Code</h2>
          <div className="flex items-center gap-1">
            <input
              type="text"
              className="profile-input input  !px-2 !py-1.5"
              placeholder="Add Referral Code"
              name="referral"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
            />
            <Button
              onClick={addReferralCode}
              variant="outlined"
              className="!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:!border-yellow-600 !rounded-lg !text-sm !px-2 !py-1.5 !capitalize  !text-gray-400 !border-gray-600">
              Add{" "}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Account
