import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, defs, linearGradient, stop, Legend } from 'recharts';
import Dropdown from '../ui/Dropdown';
import { MenuItem } from '@mui/material';
import { CustomLegend, CustomToolTip, CustomXAxisTick } from '../../utils/graphCustomeComponent';


const NewBarChart = ({ data, highestSolved }) => {
    const TOOLS = ["All", "Python", "SQL", "Excel"];
    const [selectedTool, setSelectedTool] = useState("All");
    const [aggregatedData, setAggregatedData] = useState([])
    const [isProgress, setIsProgress] = useState(true)

    const aggregateData = (data, subject) => {
        const aggregated = {};

        data.forEach(entry => {
            const levels = entry[`${subject}_levels`];
            Object.keys(levels).forEach(level => {
                if (!aggregated[level]) {
                    aggregated[level] = 0;
                }
                aggregated[level] += levels[level];
            });
        });

        const totalLevels = 10; // Example: if levels should range from 1 to 10

        // Get the keys from the aggregated object and map them
        const result = [];

        // Step 1: Add levels that are present in `aggregated`
        Object.keys(aggregated).forEach(level => {
            result.push({
                level: "Level " + level,
                total: aggregated[level]
            });
        });

        // Step 2: Add absent levels (those not in `aggregated`)
        for (let i = 1; i <= totalLevels; i++) {
            const levelStr = "Level " + i;
            if (!aggregated.hasOwnProperty(i.toString())) { // Check if the level is absent
                result.push({
                    level: levelStr,
                    total: 0 // You can set this to 0 or another value
                });
            }
        }

        result.sort((a, b) => {
            // Extract the numerical part of the level string (e.g., "Level 1" => 1)
            const levelA = parseInt(a.level.split(" ")[1]);
            const levelB = parseInt(b.level.split(" ")[1]);

            return levelA - levelB; // Sort in ascending order (1, 2, 3, ...)
        });

        const isProg = result?.filter((item) => item.total !== 0).length !== 0

        setIsProgress(isProg)

        return result;

    };

    const aggregateAllSubjects = (data) => {
        const levelCounts = {};

        // Aggregate counts for each level
        data.forEach(entry => {
            for (const subject of Object.keys(entry)) {
                if (subject === 'name') continue;

                const questions = entry[subject];

                for (const [level, count] of Object.entries(questions)) {
                    if (levelCounts[level]) {
                        levelCounts[level] += parseInt(count, 10);
                    } else {
                        levelCounts[level] = parseInt(count, 10);
                    }
                }
            }
        });

        const totalLevels = 10; // Example: if levels should range from 1 to 10
        const result = [];

        // Step 1: Add levels that are present in `levelCounts`
        Object.entries(levelCounts).forEach(([level, total]) => {
            result.push({
                level: "Level " + level,
                total
            });
        });

        // Step 2: Add absent levels (those not in `levelCounts`)
        for (let i = 1; i <= totalLevels; i++) {
            const levelStr = "Level " + i;
            if (!levelCounts.hasOwnProperty(i.toString())) { // Check if the level is absent
                result.push({
                    level: levelStr,
                    total: 0 // You can set this to 0 or another value
                });
            }
        }

        // Step 3: Sort the result array by the numeric value of the level
        result.sort((a, b) => {
            const levelA = parseInt(a.level.split(" ")[1]);
            const levelB = parseInt(b.level.split(" ")[1]);

            return levelA - levelB; // Sort in ascending order (1, 2, 3, ...)
        });

        const isProg = result?.filter((item) => item.total !== 0).length !== 0

        setIsProgress(isProg)

        return result;
    }


    useEffect(() => {
        if (selectedTool !== "All") {
            const aggregatedData = aggregateData(data, selectedTool)

            setAggregatedData(aggregatedData);

        } else {
            const aggregatedData = aggregateAllSubjects(data)
            setAggregatedData(aggregatedData);
        }
    }, [selectedTool, data])
    return (
        <>
            <div className='mr-auto px-4 my-2'>
                <Dropdown
                    title={selectedTool}
                    children=
                    {TOOLS.map((tool, index) => (
                        <MenuItem
                            onClick={() => { setSelectedTool(tool) }}
                            className='!text-gray-200 !font-light !text-xs'
                            value={tool} key={tool}>{tool}</MenuItem>
                    ))}
                />
            </div>
            <div className='flex flex-col h-full w-full'>
                {
                    aggregatedData.length === 0 ?
                        <div className='h-[250px] flex justify-center items-center'>
                            <h1 className="text-sm text-center text-gray-400">No Data to Display <br></br>Solve Questions to see your Progress!</h1>
                        </div>
                        :
                        <div>
                            <ResponsiveContainer className="!p-0" width="100%" height={!isProgress ? 200 : 250}>
                                <BarChart width={600} height={300} data={aggregatedData}>
                                    <defs>
                                        <linearGradient
                                            id="SQL"
                                            x1="0%"
                                            y1="0%"
                                            x2="100%"
                                            y2="100%">
                                            <stop
                                                offset="0%"
                                                style={{ stopColor: "#9f1239", stopOpacity: 1 }}
                                            />
                                            <stop
                                                offset="100%"
                                                style={{ stopColor: "#fb7185", stopOpacity: 1 }}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="Python"
                                            x1="0%"
                                            y1="0%"
                                            x2="100%"
                                            y2="100%">
                                            <stop
                                                offset="0%"
                                                style={{ stopColor: "#86198f", stopOpacity: 1 }}
                                            />
                                            <stop
                                                offset="100%"
                                                style={{ stopColor: "#e879f9", stopOpacity: 1 }}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="Excel"
                                            x1="0%"
                                            y1="0%"
                                            x2="100%"
                                            y2="100%">
                                            <stop
                                                offset="0%"
                                                style={{ stopColor: "#166534", stopOpacity: 1 }}
                                            />
                                            <stop
                                                offset="100%"
                                                style={{ stopColor: "#4ade80", stopOpacity: 1 }}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="colorAll"
                                            x1="0%"
                                            y1="0%"
                                            x2="0%"
                                            y2="100%">
                                            <stop
                                                offset="0%"
                                                style={{ stopColor: "#3b82f6", stopOpacity: 1 }}
                                            />
                                            <stop
                                                offset="100%"
                                                style={{ stopColor: "#1e40af", stopOpacity: 1 }}
                                            />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="level"
                                        axisLine={{ stroke: "#94a3b8" }}
                                        tickLine={{ stroke: "#94a3b8" }}
                                        tick={<CustomXAxisTick />}
                                        interval={0}
                                        // angle={-20}
                                        textAnchor="middle"
                                        height={50}
                                        fontSize={12}
                                        dy={10}
                                    />
                                    <YAxis
                                        axisLine={{ stroke: "#94a3b8" }}
                                        tickLine={{ stroke: "#94a3b8" }}
                                        tick={{ fill: "#94a3b8" }}
                                        fontSize={12}
                                        domain={[0, highestSolved?.overall]}
                                        allowDecimals={false}

                                        label={{
                                            value: "Questions Solved",
                                            angle: -90,
                                            fill: "#94a3b8",
                                            position: "insideLeft",
                                            dy: 70,
                                            offset: 20
                                        }}
                                    />
                                    <Tooltip content={<CustomToolTip />} cursor={{ fill: '#374151', opacity: ".5" }} />
                                    <Bar dataKey="total"
                                        fill={`url(#${selectedTool === "All" ? "colorAll" : selectedTool})`}
                                        radius={[8, 8, 0, 0]}
                                        barSize={16}
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                            {
                                !isProgress && <h4 className="text-center text-xs">You haven't solved any questions during selected time. <br></br> To track your progress, either change the filter or start solving questions.</h4>
                            }
                        </div>
                }
            </div>
        </>
    )
}


export default NewBarChart;
