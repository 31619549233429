import Cookies from "js-cookie"
const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

function getMessage(responeObj) {
  let message = responeObj.message
  if (typeof responeObj.error === "string") message = responeObj.error
  return message
}

const signupApi = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestObj),
  })

  let responeObj = await response.json()
  return { data: responeObj.data, status: response.status, message: getMessage(responeObj) }
}

const sendVerificationEmail = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/sendVerificationEmail", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${localStorage.usertoken}`,
    },
    body: JSON.stringify(requestObj),
  })
  let responseObj = await response.json()
  return { status: response.status, message: getMessage(responseObj) }
}

const verifyOTP = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/verifyOtp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${localStorage.usertoken}`,
    },
    body: JSON.stringify(requestObj),
  })
  let responseObj = await response.json()
  console.log(responseObj)
  return { status: response.status, message: responseObj.message || responseObj.error, data: responseObj.data }
}

const sendResetLink = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/sendresetlink", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestObj),
  })
  let responseObj = await response.json()
  return { status: response.status, message: getMessage(responseObj) }
}

const siginInApi = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/signin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestObj),
  })

  let responeObj = await response.json()
  return { data: responeObj.data, status: response.status, message: getMessage(responeObj) }
}
const updatePassword = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/updatepwd", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestObj),
  })
  let responeObj = await response.json()
  return { data: responeObj.data, status: response.status, message: getMessage(responeObj) }
}

const verifyCaptcha = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/verify-captcha", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestObj),
  })
  let responeObj = await response.json()
  return { success: responeObj.success, status: response.status, message: responeObj.message || responeObj.error }
}

const getUserDetails = async () => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/getUserDetails", {
    method: "GET",
    headers: { Authorization: ` ${localStorage.usertoken}` },
  })

  let responeObj = await response.json()

  return { data: responeObj.data, status: response.status, message: responeObj.message || responeObj.error }
}

const updateUserDetails = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/updateUserDetails", {
    method: "POST",
    headers: { Authorization: ` ${localStorage.usertoken}` },
    body: JSON.stringify(requestObj),
  })

  let responeObj = await response.json()
  return { data: responeObj.data, status: response.status, message: responeObj.message || responeObj.error }
}

async function update(userData) {
  const response = await fetch(REACT_APP_API_ENDPOINT + `/api/user/update/`, {
    method: "PATCH", // Specify the method to use
    headers: {
      Authorization: ` ${localStorage.usertoken}`,
      "Content-Type": "application/json", // Indicate the type of content expected
    },
    body: JSON.stringify(userData), // Convert the JavaScript object to a JSON string
  })
  let responeObj = await response.json()
  return { data: responeObj.data, status: response.status, message: responeObj.message || responeObj.error }
}
const fetchQuestion = async (queryParams = "", signal) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + `/api/question/get-adaptive-question?${queryParams.toString()}`, {
    method: "GET",
    headers: { Authorization: ` ${localStorage.usertoken}` },
    signal,
  })

  let responeObj = await response.json()
  return { data: responeObj.data, status: response.status, message: getMessage(responeObj) }
}
const fetchArenaQuestion = async (queryParams = "") => {
  const response = await fetch(REACT_APP_API_ENDPOINT + `/api/question/get-arena-question?${queryParams.toString()}`, {
    method: "GET",
    headers: { Authorization: ` ${localStorage.usertoken}` },
  })

  let responeObj = await response.json()
  return { data: responeObj.data, status: response.status, message: getMessage(responeObj) }
}
const fetchSearchFields = async (queryParams = "") => {
  const response = await fetch(REACT_APP_API_ENDPOINT + `/api/question/get-search-fields?${queryParams.toString()}`, {
    method: "GET",
    headers: { Authorization: ` ${localStorage.usertoken}` },
  })

  let responseObj = await response.json()
  return { data: responseObj.data, status: response.status, message: getMessage(responseObj) }
}

const getExcelFile = async (queryParams = "") => {
  const response = await fetch(REACT_APP_API_ENDPOINT + `/api/question/generate-excel-file?${queryParams.toString()}`, {
    method: "GET",
    headers: { Authorization: ` ${localStorage.usertoken}` },
  })

  let responseObj = await response.json()
  return { data: responseObj.data, status: response.status, message: getMessage(responseObj) }
}

const validateAnswer = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + `/api/question/validate-user-answers`, {
    method: "POST",
    headers: { Authorization: ` ${localStorage.usertoken}` },
    body: requestObj,
  })
  let responeObj = await response.json()
  return { data: responeObj.data, status: response.status, message: getMessage(responeObj), rewardPoints: responeObj.rewardPoints, additionalMessage: responeObj.additionalMessage, updatedLevel: responeObj.updatedLevel }
}

const runQuery = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + `/api/question/run`, {
    method: "POST",
    headers: { Authorization: ` ${localStorage.usertoken}` },
    body: requestObj,
  })

  let responeObj = await response.json()

  return { data: responeObj.data, status: response.status, message: getMessage(responeObj) }
}

const gePlans = async () => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/pricing/get-plans", {
    method: "GET",
    headers: { Authorization: ` ${localStorage.usertoken}` },
  })

  let responeObj = await response.json()
  return { data: responeObj.data, status: response.status, message: responeObj.message || responeObj.error }
}

const createOrder = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/create-order", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${localStorage.usertoken}`,
    },
    body: JSON.stringify(requestObj),
  })
  let responeObj = await response.json()
  return { data: responeObj.data, status: response.status, message: responeObj.message || responeObj.error }
}

const verifyOrder = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/verify-order", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${localStorage.usertoken}`,
    },
    body: JSON.stringify(requestObj),
  })
  let responeObj = await response.json()
  return { data: responeObj.data, status: response.status, message: responeObj.message || responeObj.error }
}

const getFaqsData = async () => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/faqs/getFaqsData", {
    method: "GET",
    //headers: { Authorization: ` ${localStorage.usertoken}` }
  })

  let responeObj = await response.json()
  return { data: responeObj.data, status: response.status, message: responeObj.message }
}

const arenaFormData = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/preloginforms/arenaform", {
    method: "POST",
    body: JSON.stringify(requestObj),
  })
  let responseObj = await response.json()
  return { data: responseObj.data, status: response.status, message: responseObj.message || responseObj.error }
}

const recruitFormData = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/preloginforms/recruitform", {
    method: "POST",
    body: JSON.stringify(requestObj),
  })
  let responseObj = await response.json()
  return { data: responseObj.data, status: response.status, message: responseObj.message || responseObj.error }
}

const sendGoogleTokenToBackend = async (token) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/verify-google-token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token: token }),
  })
  let responseObj = await response.json()
  return { data: responseObj, status: response.status, message: responseObj.message || responseObj.error }
}

const sendLinkedinCodeToBackend = async (code) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/verify-linkedin-code", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code: code }),
  })
  let responseObj = await response.json()
  return { data: responseObj, status: response.status, message: responseObj.message || responseObj.error }
}

const fetchTwitterToken = async () => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/fetch-twitter-token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
  let responseObj = await response.json()
  return { data: responseObj, status: response.status, message: responseObj.message || responseObj.error }
}

const getAccessTokenTwitter = async (oauthToken, oauthVerifier) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/verify-twitter-token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ oauthToken, oauthVerifier }),
  })
  let responseObj = await response.json()
  return { data: responseObj.token, status: response.status, message: responseObj.message || responseObj.error }
}

const addSubscriber = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/addSubscriber", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestObj),
  })
  let responseObj = await response.json()
  return { status: response.status, message: responseObj.message }
}

const fetchBarChartData = async (uniqueID = null, filterParams = {}) => {
  let path = `/api/dashboard/barchart`
  const headers = {
    "Content-Type": "application/json",
  }

  const params = new URLSearchParams()

  if (uniqueID) {
    params.append("uniqueId", encodeURIComponent(uniqueID))
  } else {
    headers.Authorization = `${localStorage.usertoken}`
  }

  if (filterParams.filterType) {
    params.append("filterType", filterParams.filterType)
  }
  if (filterParams.arenaId) {
    params.append("arenaId", filterParams.arenaId)
  }
  if (filterParams.roadmapName) {
    params.append("roadmapName", filterParams.roadmapName)
  }

  if (params.toString()) {
    path += `?${params.toString()}`
  }

  const response = await fetch(REACT_APP_API_ENDPOINT + path, {
    method: "GET",
    headers: headers,
  })

  let responseObj = await response.json()

  return { status: response.status, data: responseObj.barChartData, pieChartData: responseObj.pieChartData, highestSolved: responseObj?.highestSolved }
}

const fetchPieChartData = async (uniqueID = null, filterParams = {}) => {
  let path = `/api/dashboard/piechart`
  const headers = {
    "Content-Type": "application/json",
  }

  const params = new URLSearchParams()

  if (uniqueID) {
    params.append("uniqueId", encodeURIComponent(uniqueID))
  } else {
    headers.Authorization = `${localStorage.usertoken}`
  }

  if (filterParams.filterType) {
    params.append("filterType", filterParams.filterType)
  }
  if (filterParams.arenaId) {
    params.append("arenaId", filterParams.arenaId)
  }
  if (filterParams.roadmapName) {
    params.append("roadmapName", filterParams.roadmapName)
  }

  if (params.toString()) {
    path += `?${params.toString()}`
  }

  const response = await fetch(REACT_APP_API_ENDPOINT + path, {
    method: "GET",
    headers: headers,
  })

  let responseObj = await response.json()
  return { status: response.status, data: responseObj.data }
}

const fetchAvailableArenasList = async () => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/arena/get-available-arenas", {
    method: "GET",
    headers: {
      Authorization: `${localStorage.usertoken}`,
    },
  })
  let responseObj = await response.json()
  return { status: response.status, data: responseObj }
}

const fetchEnrolledArenasList = async () => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/arena/get-enrolled-arenas", {
    method: "GET",
    headers: {
      Authorization: `${localStorage.usertoken}`,
    },
  })
  let responseObj = await response.json()
  return { status: response.status, data: responseObj }
}

const fetchArenaByName = async (name) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + `/api/arena/list-arenas/${name}`, {
    method: "GET",
    headers: {
      Authorization: `${localStorage.usertoken}`,
    },
  })
  let responseObj = await response.json()
  return { status: response.status, data: responseObj }
}

const fetchLeaderBoardData = async (filterParams = {}) => {
  let path = `/api/dashboard/leaderboard`

  const params = new URLSearchParams()

  if (filterParams.filterType) {
    params.append("filterType", filterParams.filterType)
  }
  if (filterParams.arenaId) {
    params.append("arenaId", filterParams.arenaId)
  }
  if (filterParams.roadmapName) {
    params.append("roadmapName", filterParams.roadmapName)
  }

  if (params.toString()) {
    path += `?${params.toString()}`
  }
  const response = await fetch(REACT_APP_API_ENDPOINT + path, {
    method: "GET",
    headers: {
      Authorization: `${localStorage.usertoken}`,
    },
  })
  let responseObj = await response.json()
  return { status: response.status, data: responseObj.data, message: responseObj.message }
}
const userperformanceData = async (uniqueID = null, filterParams = {}) => {
  let path = `/api/dashboard/userperformancedata`
  const headers = {
    "Content-Type": "application/json",
  }

  const params = new URLSearchParams()

  if (uniqueID) {
    params.append("uniqueId", encodeURIComponent(uniqueID))
  } else {
    headers.Authorization = `${localStorage.usertoken}`
  }

  if (filterParams.filterType) {
    params.append("filterType", filterParams.filterType)
  }
  if (filterParams.arenaId) {
    params.append("arenaId", filterParams.arenaId)
  }
  if (filterParams.roadmapName) {
    params.append("roadmapName", filterParams.roadmapName)
  }

  if (params.toString()) {
    path += `?${params.toString()}`
  }

  const response = await fetch(REACT_APP_API_ENDPOINT + path, {
    method: "GET",
    headers: headers,
  })
  let responseObj = await response.json()
  return { status: response.status, data: responseObj.data, message: responseObj.message }
}
const userarenaperformanceData = async (uniqueID = null, filterParams = {}) => {
  let path = `/api/dashboard/userarenaperformancedata`
  const headers = {
    "Content-Type": "application/json",
  }

  const params = new URLSearchParams()

  if (uniqueID) {
    params.append("uniqueId", encodeURIComponent(uniqueID))
  } else {
    headers.Authorization = `${localStorage.usertoken}`
  }

  if (filterParams.filterType) {
    params.append("filterType", filterParams.filterType)
  }
  if (filterParams.arenaId) {
    params.append("arenaId", filterParams.arenaId)
  }
  if (filterParams.roadmapName) {
    params.append("roadmapName", filterParams.roadmapName)
  }

  if (params.toString()) {
    path += `?${params.toString()}`
  }
  const response = await fetch(REACT_APP_API_ENDPOINT + path, {
    method: "GET",
    headers: headers,
  })
  let responseObj = await response.json()
  return { status: response.status, data: responseObj.data, message: responseObj.message }
}
const getQuestionsAttemptedByLevel = async () => {
  const response = await fetch(REACT_APP_API_ENDPOINT + `/api/dashboard/getbylevel`, {
    method: "GET",
    headers: {
      Authorization: `${localStorage.usertoken}`,
    },
  })
  let responseObj = await response.json()
  return { status: response.status, data: responseObj.data, message: responseObj.message }
}

const getQuestionHistoryList = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/question/get-question-history", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.usertoken}`,
    },
    body: JSON.stringify(requestObj),
  })
  let responseObj = await response.json()
  return { status: response.status, data: responseObj.data, message: responseObj.message, lastUpdated: responseObj.lastUpdated }
}

const validateCouponCode = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/coupon/validate-coupon", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${localStorage.usertoken}`,
    },
    body: JSON.stringify(requestObj),
  })
  let responseObj = await response.json()
  return {
    status: response.status,
    message: responseObj.message,
    discountAmount: responseObj.discountAmount,
    error: responseObj.error,
    type: responseObj.type,
  }
}
const fetchFChartData = async (uniqueID = null, filterParams = {}) => {
  let path = "/api/dashboard/getfchart"
  const params = new URLSearchParams()
  const headers = {
    "Content-Type": "application/json",
  }

  if (uniqueID) {
    params.append("uniqueId", encodeURIComponent(uniqueID))
  } else {
    headers.Authorization = `${localStorage.usertoken}`
  }

  if (filterParams.filterType) {
    params.append("filterType", filterParams.filterType)
  }
  if (filterParams.arenaId) {
    params.append("arenaId", filterParams.arenaId)
  }
  if (filterParams.roadmapName) {
    params.append("roadmapName", filterParams.roadmapName)
  }

  if (params.toString()) {
    path += `?${params.toString()}`
  }

  const response = await fetch(REACT_APP_API_ENDPOINT + path, {
    method: "GET",
    headers: headers,
  })

  let responseObj = await response.json()
  return { status: response.status, data: responseObj.data, message: responseObj.message }
}

const fetchUpdatedToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken")
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/updated-token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ refreshToken }),
  })
  let responseObj = await response.json()
  return { status: response.status, message: responseObj.message, token: responseObj.token, error: responseObj.error }
}

const addMRQuestion = async (requestObj) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + `/api/mr-question/add`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.usertoken}`,
    },
    body: JSON.stringify(requestObj),
  })
  let responseObj = await response.json()
  return { status: response.status, message: responseObj.message, data: responseObj.data }
}

const getBasicInfo = async () => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/basicinfo", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${localStorage.usertoken}`,
    },
  })
  let responseObj = await response.json()

  return { status: response.status, data: responseObj.data, message: responseObj.message }
}

const updateBasicInfo = async ({ userData }) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/update-basicinfo", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${localStorage.usertoken}`,
    },
    body: JSON.stringify({ ...userData }),
  })
  let responseObj = await response.json()
  return { status: response.status, data: responseObj.data, message: responseObj.message }
}

const updateAccountInfo = async (user) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/update-accountinfo", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${localStorage.usertoken}`,
    },
    body: JSON.stringify({ ...user }),
  })
  let responseObj = await response.json()
  return { status: response.status, data: responseObj.data, message: responseObj.message }
}

const getSubscriptionInfo = async () => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user-subscription-history/getSubscriptionHistory", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${localStorage.usertoken}`,
    },
  })
  let responseObj = await response.json()
  return { status: response.status, data: responseObj.data, message: responseObj.message }
}

const unseenNotificationsCount = async () => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/notification/unseen-count", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${localStorage.usertoken}`,
    },
  })
  let responseObj = await response.json()

  return { status: response.status, unseenCount: responseObj.unseenCount, message: responseObj.error }
}

const getNotifications = async () => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/notification/notifications", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${localStorage.usertoken}`,
    },
  })
  let responseObj = await response.json()

  return { status: response.status, data: responseObj, message: responseObj.error }
}

const handleSeeNotification = async (notificationId) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/notification/mark-as-seen", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${localStorage.usertoken}`,
    },
    body: JSON.stringify({ notificationId }),
  })
  let responseObj = await response.json()
  return { status: response.status, data: responseObj.data, message: responseObj.message }
}

const updateTimer = async ({ userQuestionHistoryId, time }) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/question/updateTimeSpent", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.usertoken}`,
    },
    body: JSON.stringify({ userQuestionHistoryId, time }),
    keepalive: true,
  })
  let responseObj = await response.json()
  return { status: response.ok, data: responseObj.data, message: responseObj.message }
}

const getAvailableCoupons = async () => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/coupon/get-coupons", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${localStorage.usertoken}`,
    },
  })
  let responseObj = await response.json()
  return { status: response.status, data: responseObj.coupons, message: responseObj.message }
}

const getRoadmapQuestions = async (arenaName, roadmapName, roadmapDescription) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/arena/get-roadmap-questions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${localStorage.usertoken}`,
    },
    body: JSON.stringify({ arenaName, roadmapName, roadmapDescription }),
  })
  let responseObj = await response.json()

  return { status: response.status, data: responseObj.data, message: responseObj.message }
}

const updateUserDashboardSettings = async (settings) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/update-dashboard-settings", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${localStorage.usertoken}`,
    },
    body: JSON.stringify({ dashboardSettings: settings }),
  })
  let responseObj = await response.json()
  console.log({ responseObj })
  return { status: response.status, data: responseObj.data, message: responseObj.message }
}

const addReferral = async (referralCode) => {
  const response = await fetch(REACT_APP_API_ENDPOINT + "/api/user/add-referral", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${localStorage.usertoken} `,
    },
    body: JSON.stringify({ referralCode }),
  })
  let responseObj = await response.json()
  return { status: response.status, data: responseObj.data, message: responseObj.message }
}

export {
  signupApi,
  siginInApi,
  getUserDetails,
  fetchQuestion,
  fetchSearchFields,
  getExcelFile,
  validateAnswer,
  runQuery,
  gePlans,
  sendVerificationEmail,
  updatePassword,
  updateUserDetails,
  verifyOTP,
  sendResetLink,
  getFaqsData,
  arenaFormData,
  recruitFormData,
  createOrder,
  verifyOrder,
  update,
  sendGoogleTokenToBackend,
  sendLinkedinCodeToBackend,
  addSubscriber,
  fetchTwitterToken,
  getAccessTokenTwitter,
  verifyCaptcha,
  fetchBarChartData,
  fetchPieChartData,
  fetchAvailableArenasList,
  fetchEnrolledArenasList,
  fetchArenaByName,
  fetchLeaderBoardData,
  getQuestionsAttemptedByLevel,
  getQuestionHistoryList,
  validateCouponCode,
  userperformanceData,
  userarenaperformanceData,
  fetchFChartData,
  fetchUpdatedToken,
  fetchArenaQuestion,
  addMRQuestion,
  getBasicInfo,
  updateBasicInfo,
  updateAccountInfo,
  getSubscriptionInfo,
  unseenNotificationsCount,
  getNotifications,
  handleSeeNotification,
  updateTimer,
  getAvailableCoupons,
  getRoadmapQuestions,
  updateUserDashboardSettings,
  addReferral,
}
