import React, { useState, useEffect } from "react"
import Cookies from "js-cookie"
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Alert } from "@mui/material"

const PolicyPopup = () => {
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const consent = Cookies.get("consent")
    if (!consent) {
      // Delay popup to improve UX
      const timer = setTimeout(() => setOpen(true), 1000)
      return () => clearTimeout(timer) // Cleanup timeout
    }
  }, [])

  const handleConsent = async (choice) => {
    try {
      // Send consent to backend
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + "/api/consent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ consent: choice }),
      })
      if (!response.ok) throw new Error("Failed to log consent")

      // Store consent in cookies
      Cookies.set("consent", choice, { expires: 365 })
      setOpen(false)
      setError(null)
    } catch (err) {
      setError("Failed to save your preference. Please try again.")
      console.error(err)
    }
  }
  const handleDialogClose = (event, reason) => {
    // Prevent closing the dialog without a decision
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return
    }
    setOpen(false) // This won't be triggered since we block unintended closes
  }

  return (
    <Dialog
      className="bg-transparent backdrop-blur-sm myCard1"
      open={open}
      //style={{ borderRadius: "18px" }}
      onClose={handleDialogClose}
      PaperProps={{
        sx: {
          background: "#1A2029",
          color: "#fefefe", // White text color
          borderRadius: "19px", // Rounded corners
          padding: "20px",
          opacity: "0.85",
        },
      }}>
      <div className="">
        <DialogTitle
          className="!title"
          sx={{
            backgroundImage: "linear-gradient(96deg, #dddcff, #c1bfff)",
            "-webkit-text-fill-color": "transparent",
            "-webkit-background-clip": "text",
          }}>
          Data Storage Policy
        </DialogTitle>
        <DialogContent>
          <p className="!text-slate-300">We use cookies to enhance your experience. Do you accept our data storage policy? You can manage your preferences at any time.</p>
          {error && <Alert severity="error">{error}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleConsent("declined")}
            variant="outlined"
            //color="error"

            className="!text-[#9c3ef9] !py-2 !px-6 !rounded-full !border-[#562E7E] !font-[500] !border-[2px]">
            Decline
          </Button>
          <Button
            onClick={() => handleConsent("accepted")}
            variant="contained"
            color="primary"
            className="cta-btn !py-2 !px-6 !rounded-full">
            Accept
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default PolicyPopup
