import { Text } from "recharts"

export const CustomXAxisTick = ({ x, y, payload }) => {
  if (payload && payload.value) {
    return (
      <Text
        fontSize={"10px"}
        width={60}
        x={x}
        y={y}
        angle={-90}
        textAnchor="middle"
        verticalAnchor="start"
        fill="#94a3b8"
        dy={16}
        dx={-4}>
        {payload.value}
      </Text>
    )
  }
  return null
}

export const CustomToolTip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#1d1d1d",
          borderRadius: 5,
          padding: 4,
          color: "#e5e7eb",
        }}>
        <p className="label">{`${label}`}</p>
        {payload.toReversed().map((entry, index) => (
          <p
            key={`item-${index}`}
            style={{ color: entry.color }}>
            {entry.name} : {entry.value}
          </p>
        ))}
      </div>
    )
  }
  return null
}


export const CustomLegend = ({dir="column"}) => {
  const payload = [
    { color: "#fb7185", value: "SQL" },
    { color: "#e879f9", value: "Python" },
    { color: "#4ade80", value: "Excel" },
  ]
  return (
    <ul
      style={{
        listStyle: "none",
        display: "flex",
        justifyContent: "center",
        flexDirection: dir,
      }}>
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{
            marginRight: 10,
            display: "flex",
            alignItems: "center",
          }}>
          <span
            style={{
              backgroundColor: entry.color,
              width: 10,
              height: 10,
              display: "block",
              marginRight: 10,
              borderRadius: "10px",
            }}></span>
          {entry.value}
        </li>
      ))}
    </ul>
  )
}