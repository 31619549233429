import React, { useState } from "react"
import { toast } from "react-toastify"
import { Container, Typography, FormControl, InputLabel, Select, MenuItem, Slider, Button, Stepper, Step, StepLabel, Box, Card, CardContent, createTheme, ThemeProvider } from "@mui/material"
import { SUBJECTS, INDUSTRIES, LEVELS, toastConfig } from "../utils/constants"
import { useNavigate } from "react-router-dom"
import { SubjectOutlined } from "@mui/icons-material"
import Dropdown from "./ui/Dropdown"
import ToastWarning from "./toasts/ToastWarning"

const steps = ["Select Tool", "Select Level"]

const theme = createTheme({
  palette: {
    primary: {
      main: "#d69e2e", // yellowish orange
    },
    secondary: {
      main: "#ff4081", // Pink A200
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          padding: "10px 20px",
        },
      },
    },
  },
  typography: {
    h4: {
      fontWeight: "bold",
      marginBottom: "20px",
      color: "#4a148c",
    },
    h6: {
      color: "#4a148c",
    },
  },
})

const ToolLevelForm = ({ onSubmit }) => {
  const navigate = useNavigate()

  const [activeStep, setActiveStep] = useState(0)
  const [selectedTool, setSelectedTool] = useState(SUBJECTS[0])
  const [level, setLevel] = useState(1)
  const [selectedIndustry, setSelectedIndustry] = useState(INDUSTRIES[0])

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)
  const handleToolChange = (val) => setSelectedTool(val)
  const handleSliderChange = (event, newValue) => setLevel(newValue)
  const handleIndustryChange = (event) => setSelectedIndustry(event.target.value)

  const handleSubmit = (event) => {
    event.preventDefault()

    // Submit data to backend or handle as needed
    if (!selectedTool || !level) {
      toast(<ToastWarning message={"Please share all your prefences"}/>, toastConfig)
      return
    }
    onSubmit({ tool: selectedTool, level: level })
  }

  return (
    <>
      {/*<ThemeProvider theme={theme}>
        <Container
          maxWidth="sm"
          className="!bg-[#232627]"
          sx={{ pt: 4 }}>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography
                variant="h4"
                gutterBottom
                align="center">
                Share your Preference
              </Typography>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{ mb: 3 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconProps={{ sx: { color: "secondary.main" } }}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <form onSubmit={handleSubmit}>
                {activeStep === 0 && (
                  <FormControl
                    fullWidth
                    margin="normal">
                    <InputLabel id="tool-select-label">Tool</InputLabel>
                    <Select
                      labelId="tool-select-label"
                      id="tool-select"
                      value={selectedTool}
                      label="Tool"
                      onChange={handleToolChange}>
                      {SUBJECTS.map((s) => {
                        return (
                          <MenuItem
                            key={s}
                            value={s}>
                            {s}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                )}
                {activeStep === 1 && (
                  <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography
                      id="level-slider"
                      gutterBottom>
                      Level (1-{LEVELS})
                    </Typography>
                    <Slider
                      value={level}
                      onChange={handleSliderChange}
                      aria-labelledby="level-slider"
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={1}
                      max={LEVELS}
                    />
                    <Typography
                      variant="h6"
                      align="center"
                      color="primary.main"
                      sx={{ mt: 2 }}>
                      Level {level}
                    </Typography>
                  </Box>
                )}
                 {activeStep === 2 && (
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="industry-select-label">Industry</InputLabel>
                                    <Select
                                        labelId="industry-select-label"
                                        id="industry-select"
                                        value={selectedIndustry}
                                        label="Industry"
                                        onChange={handleIndustryChange}
                                    >
                                         {
                                            INDUSTRIES.map(s => {
                                                return (<MenuItem value={s}>{s}</MenuItem>)
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            )} 
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}>
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {activeStep === steps.length - 1 ? <Button onClick={handleSubmit}>Finish</Button> : <Button onClick={handleNext}>Next</Button>}
                </Box>
              </form>
            </CardContent>
          </Card>
        </Container>
      </ThemeProvider> */}
      <div className="flex flex-col justify-center items-center h-[calc(100vh-50px)] bg-[#232627]">
        <div className="mx-auto my-4">
          <h2 className="font-bold text-3xl text-gray-200 my-1">Share your preferences</h2>
          <div className="gradient" />
        </div>
        <div className="flex flex-col items-center justify-center w-screen">
          <div className="shiny-border  w-full max-w-md">
            <div className=" myCard p-8 !text-[#e5e7eb] !bg-[#1f2021]">
              <Stepper
                activeStep={activeStep}
                alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconProps={{ sx: { color: "secondary.main" } }}>
                      <Typography className="!text-base !text-gray-200">{label}</Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <form
                onSubmit={handleSubmit}
                className="flex flex-col items-center justify-center w-full">
                {activeStep === 0 && (
                  <div className="flex-row-bw-container w-full gap-4 my-5">
                    {SUBJECTS.map((subject) => (
                      <Button
                        variant="outlined"
                        key={subject}
                        className={`!flex-1 !text-gray-400 !border-gray-400 !shadow-none ${selectedTool === subject ? "!text-yellow-600 !border-yellow-600" : ""} `}
                        size="small"
                        onClick={() => handleToolChange(subject)}>
                        {subject}
                      </Button>
                    ))}
                  </div>
                  /*<div className="w-full my-6">
                    <Dropdown
                      title="select Tool"
                      tooltip={`Select a tool to start your journey`}
                      className="w-full"
                      icon={<SubjectOutlined />}
                      children={SUBJECTS.map((subject) => (
                        <MenuItem
                          key={subject}
                          onClick={handleToolChange}
                          label="Tool"
                          value={subject}
                          className="!text-gray-200 !font-light !text-xs">
                          {subject}
                        </MenuItem>
                      ))}
                    />
                  </div>
                  <FormControl
                    fullWidth
                    margin="normal">
                    <InputLabel
                      id="tool-select-label"
                      className="!text-gray-200">
                      Tool
                    </InputLabel>
                    <Select
                      labelId="tool-select-label"
                      id="tool-select"
                      value={selectedTool}
                      label="Tool"
                      onChange={handleToolChange}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#e5ebe7", // Border color for the Select field
                          },
                          "&:hover fieldset": {
                            borderColor: "#e5ebe7", // Border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#e5ebe7", // Border color when focused
                          },
                        },
                      }}>
                      {SUBJECTS.map((s) => (
                        <MenuItem
                          key={s}
                          value={s}
                          sx={{
                            backgroundColor: "#343839", // Background color for MenuItem
                            "&:hover": {
                              backgroundColor: "#4a4e51", // Optional hover effect
                            },
                          }}>
                          {s}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */
                )}
                {activeStep === 1 && (
                  <Box className="w-full">
                    <Typography
                      id="level-slider"
                      gutterBottom
                      className="!text-gray-200 !my-4">
                      Level (1-{LEVELS})
                    </Typography>
                    <Slider
                      value={level}
                      onChange={handleSliderChange}
                      aria-labelledby="level-slider"
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={1}
                      max={LEVELS}
                      className="!text-gray-200"
                    />
                    <Button
                      variant="outlined"
                      size="small"
                      disabled
                      className="hover:!bg-transparent !font-medium !text-xs !px-2 !py-1.5 !capitalize !my-3 !rounded-none
                    !border-0  !border-b !text-gray-400 !border-gray-400 hover:!text-gray-200">
                      Current level: {level}
                    </Button>
                  </Box>
                )}
                <div className="w-full flex flex-row items-center justify-center">
                  <Button
                    variant="outlined"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className="!font-medium !bg-gray-600 !text-gray-200 !shadow-none !border-none"
                    size="small">
                    Back
                  </Button>
                  <div className="flex-1" />
                  {activeStep === steps.length - 1 ? (
                    <Button
                      onClick={handleSubmit}
                      className="!bg-gray-600 !text-gray-200 !shadow-none"
                      size="small">
                      Finish
                    </Button>
                  ) : (
                    <Button
                      onClick={handleNext}
                      variant="outlined"
                      className=" !text-gray-400 !border-gray-400 !shadow-none"
                      size="small">
                      Next
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        {/*<div className="flex flex-col items-center justify-center w-screen">
          <div className="shiny-border  w-full max-w-md">
            <div className=" myCard p-8 !text-[#e5e7eb] !bg-[#1f2021]">
              <form
                onSubmit={handleSubmit}
                className="flex flex-col items-center justify-center w-full">
                <Dropdown
                  title="Tools"
                  tooltip={`Select a tool to start your journey`}
                  className="w-full"
                  icon={<SubjectOutlined />}
                  children={SUBJECTS.map((subject) => (
                    <MenuItem
                      key={subject}
                      onClick={() => {
                        handleToolChange(subject)
                      }}
                      label="Tool"
                      value={subject}
                      className="!text-gray-200 !font-light !text-xs">
                      {subject}
                    </MenuItem>
                  ))}
                />
              </form>
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default ToolLevelForm
