import React, { useEffect } from "react"
import MarmaInsightDisplay from "./MarmaInsightDisplay.jsx"
import NewsLetterSection from "./NewsletterSection.jsx"
import Footer from "./Footer.jsx"
import Hero from "./Hero.jsx"
import GetIn from "./GetIn.jsx"
import PolicyPopup from "./policy-popup.jsx"

const Homepage = () => {
  return (
    <div className="min-h-screen w-full flex flex-col items-center justify-center">
      <div className="w-full">
        <Hero />
        <MarmaInsightDisplay />
        <GetIn />
        <NewsLetterSection />
        <Footer />
        <PolicyPopup />
      </div>
    </div>
  )
}

export default Homepage
